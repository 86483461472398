export default {
    init() {
            Promise.all(Array.from(document.images).filter(img => !img.complete).map(img => new Promise(resolve => { img.onload = img.onerror = resolve; }))).then(() => {
                setTimeout(function () {
                    jQuery(document).ready(function($) {
                        let highest = null;
                        let hi = 0;
                        $('.front__cat__item').each(function () {
                            let h = $(this).height();
                            if (h > hi) {
                                hi = h;
                                highest = $(this);
                            }
                        });

                        $('.front__cat__inner').css('height', highest.innerHeight() + 2);

                        let $parentWidth = $('.front__cat__item').innerWidth() + 2;
                        let $childWidth = $('.front__cat__img').innerWidth();

                        let calc = ($parentWidth - $childWidth) / 4;

                        $('.front__cat__img').css({
                            'margin-left': calc / 2,
                        });

                        $('.front__cat__img').each(function () {
                            let imgWidth = $(this).find('img').innerWidth();
                            $(this).find('img').css('width', imgWidth);
                        });
                    });
                }, 500)
            });

        $('.front__cat__img').click(function () {
            if ($(this).closest('.front__cat__item').hasClass('open')) {
                let $default = $(this)
                    .find('.front__cat__img__src__default')
                    .data('default');
                $(this)
                    .find('.front__cat__img__src__default')
                    .attr('src', $default);

                $('.front__cat__img__src__default').removeClass('hide');
                $('.front__cat__item').removeClass('hide open');
            } else {
                $('.front__cat__item').addClass('hide');
                $(this).closest('.front__cat__item').toggleClass('hide open');
            }

            if (!$(this).closest('.front__cat__item.left').hasClass('open')) {
                $('.front__cat__content.left').removeAttr('style');

                $('.front__cat__img__src').removeClass('show');
                $('.front__cat__img__src__default').removeClass('hide');
            } else {
                $('.front__cat__content.left').css({
                    opacity: '1',
                    'pointer-events': 'all',
                    transition: 'all 300ms ease-in-out',
                });
            }

            if (!$(this).closest('.front__cat__item.right').hasClass('open')) {
                $('.front__cat__content.right').removeAttr('style');

                $('.front__cat__img__src').removeClass('show');
                $('.front__cat__img__src__default').removeClass('hide');
            } else {
                $('.front__cat__content.right').css({
                    opacity: '1',
                    'pointer-events': 'all',
                    transition: 'all 300ms ease-in-out',
                });
            }
        });

        $('.front__cat__item.left').click(function () {
            $('.front__cat__item.right').css({
                position: 'absolute',
                left: '50%',
            });
            setTimeout(function () {
                $('.front__cat__item.right').removeAttr('style');
            }, 300);
        });

        $('.front__cat__back').click(function () {
            $('.front__cat__content').removeAttr('style');
            $('.front__cat__item').removeClass('open hide');

            $('.front__cat__img__src__default').removeClass('hide');
            $('.front__cat__img__src').removeClass('show');

            if ($(this).parent().parent().hasClass('left')) {
                resetImage($('.left'));
            } else if ($(this).parent().parent().hasClass('right')) {
                resetImage($('.right'));
            }

            function resetImage($el) {
                let $default = $el
                    .find('.front__cat__img > img')
                    .data('default');
                $el.find('.front__cat__img > img').attr('src', $default);
            }
        });

        $('.left .front__cat__list__item').hover(function () {
            $('.left .front__cat__img__src__default').addClass('hide');
            $('.left .front__cat__img__src').removeClass('show');

            let dataCat = $(this).data('cat');

            $('.left .front__cat__img__src').each(function () {
                let imgCat = $(this).data('image');
                if (dataCat == imgCat) {
                    $(this).addClass('show');
                }
            });
        });

        $('.right .front__cat__list__item').hover(function () {
            $('.right .front__cat__img__src__default').addClass('hide');
            $('.right .front__cat__img__src').removeClass('show');

            let dataCat = $(this).data('cat');

            $('.right .front__cat__img__src').each(function () {
                let imgCat = $(this).data('image');
                if (dataCat == imgCat) {
                    $(this).addClass('show');
                }
            });
        });
    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
    },
};
