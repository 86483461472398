export default {
    init() {
        // JavaScript to be fired on all pages

        $(window).scroll(function() {
            let scroll = $(window).scrollTop();
            if (scroll > 100) {
                $('body').addClass('header__fixed');
            }
            else {
                $('body').removeClass('header__fixed');
            }
        });

        var $grid = $('.grid').imagesLoaded( function() {
          // init Masonry after all images have loaded
          $grid.masonry({
            itemSelector: '.grid__item',
            columnWidth: '.grid__sizer',
            percentPosition: true,
          });
        });

        $('[data-fancybox]').fancybox({
            buttons: [
                'zoom',
                // 'share',
                // 'slideShow',
                'fullScreen',
                'download',
                // 'thumbs',
                'close',
            ],
        });

        $('.slick-slider').css('opacity', '1');

        document.addEventListener('DOMContentLoaded', function () {
            let langDropdown = document.getElementById('lang-dropdown');
            let langOptions = document.getElementById('lang-options');

            langDropdown.addEventListener('mouseover', function () {
                langOptions.style.display = 'block';
            });

            langDropdown.addEventListener('mouseleave', function () {
                langOptions.style.display = 'none';
            });
        });


    },
    finalize() {
        // JavaScript to be fired on all pages, after page specific JS is fired
    },
};
